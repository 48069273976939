import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import BlogPage from '@scenes/BlogPage';
import Layout from '@components/Layout';

const Page = ({ data }) => {
  const blogpageContent = data?.allPrismicBlogpage?.edges[0];
  if (!blogpageContent) return null;
  const blogpage = blogpageContent.node;

  const { id, uid, lang, type, alternate_languages, data: pageData } = blogpage;
  const activeDocMeta = { id, uid, lang, type, alternate_languages };

  const { metatitle, metadescription, canonical } = pageData;

  return (
    <Layout
      activeDocMeta={activeDocMeta}
      metatitle={metatitle}
      metadescription={metadescription}
      canonical={canonical}
    >
      <BlogPage content={data} metatitle={metatitle} canonical={canonical} />
    </Layout>
  );
};

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query($uid: String, $lang: String) {
    allPrismicBlogpage(filter: { uid: { eq: $uid }, lang: { eq: $lang } }) {
      edges {
        node {
          uid
          type
          lang
          id
          alternate_languages {
            id
            lang
            type
            uid
          }
          data {
            canonical {
              text
            }
            metadescription {
              text
            }
            metatitle {
              text
            }
            title {
              richText
            }
            buttontext {
              text
            }
            subtitle {
              text
            }
            placeholder {
              text
            }
            filtersbuttontext {
              text
            }
            cta_button_text {
              richText
            }
            cta_button_link {
              url
            }
          }
        }
      }
    }
    allPrismicBlogpostpage(
      filter: { lang: { eq: $lang } }
      limit: 1000001
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          uid
          type
          lang
          id
          alternate_languages {
            id
            lang
            type
            uid
          }
          tags
          data {
            backgroundpreview {
              alt
              url
            }
            date
            description {
              richText
            }
            title {
              richText
            }
            preview {
              alt
              url
            }
            body {
              ... on PrismicBlogpostpageDataBodyAgencies {
                id
                slice_type
                primary {
                  buttonlink {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  description {
                    richText
                  }
                  image {
                    alt
                    url
                  }
                  sectiontitle {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyBlogDetailsPageCtaButton {
                id
                primary {
                  cta_options
                  blog_page_cta_button_link {
                    url
                  }
                  blog_page_cta_button_text {
                    richText
                  }
                  cta_header_title {
                    richText
                  }
                  cta_header_description {
                    richText
                  }
                  blog_page_cta_button_text {
                    richText
                  }
                  blog_page_cta_button_link {
                    url
                  }
                  logo {
                    url
                    alt
                  }
                }
                slice_type
              }
              ... on PrismicBlogpostpageDataBodyArticles {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttontext {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyCodeSnippet {
                id
                slice_type
                primary {
                  quote {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyCentralizedCtaFromBlogSingle {
                id
                slice_type
              }
              ... on PrismicBlogpostpageDataBodyImage {
                id
                slice_type
                primary {
                  caption {
                    richText
                  }
                  image {
                    alt
                    url
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyQuote {
                id
                slice_type
                primary {
                  quote {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodySubscribe {
                id
                slice_type
                primary {
                  title {
                    richText
                  }
                  buttontext {
                    richText
                  }
                  placeholder {
                    text
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyTable {
                id
                slice_type
                items {
                  col1 {
                    richText
                  }
                  col2 {
                    richText
                  }
                  col3 {
                    richText
                  }
                  col4 {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyTableRowsHeaders {
                id
                slice_type
                items {
                  col1 {
                    richText
                  }
                  col2 {
                    richText
                  }
                  col3 {
                    richText
                  }
                  col4 {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyTwitterEmbedPost {
                id
                slice_type
                items {
                  twitter_post
                }
              }
              ... on PrismicBlogpostpageDataBodyText {
                id
                slice_type
                primary {
                  text {
                    richText
                  }
                }
              }
              ... on PrismicBlogpostpageDataBodyVideo {
                id
                slice_type
                primary {
                  video {
                    link_type
                    url
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default Page;
